export default class ErpCustomerEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#erp_customer_id") ? (elem.querySelector("#erp_customer_id") as HTMLInputElement).value : null,
            company: (elem.querySelector("#erp_customer_company") as HTMLInputElement).value,
            firstName: (elem.querySelector("#erp_customer_firstName") as HTMLInputElement).value,
            lastName: (elem.querySelector("#erp_customer_lastName") as HTMLInputElement).value,
            street: (elem.querySelector("#erp_customer_street") as HTMLInputElement).value,
            zipcode: (elem.querySelector("#erp_customer_zipcode") as HTMLInputElement).value,
            city: (elem.querySelector("#erp_customer_city") as HTMLInputElement).value,
            email: (elem.querySelector("#erp_customer_email") as HTMLInputElement).value,
            internal_customer_uuid: (elem.querySelector("#erp_internal_customer_uuid option:checked") as HTMLInputElement).value,
            countryId: (elem.querySelector("#erp_customer_county_id option:checked") as HTMLInputElement).value,
            salutationId: (elem.querySelector("#erp_customer_salutation_id option:checked") as HTMLInputElement).value
        }
    }
}